<script setup lang="ts">

useHead({
  title: 'Giris Yap',
  meta: [
    {
      name: 'description',
      content: 'Giris Yap'
    }
  ]
})
definePageMeta({
  layout: 'empty',
})
const errorText = ref('');
const { username, password, login } = useAuth();

const loginBtn = async () => {
  errorText.value = '';
  const { status} = await login();
  if(status.value !== 'success'){
    errorText.value = "Kullanıcı adı veya şifre hatalı";
  }
}
</script>
<template>
  <div class="flex min-h-screen bg-base-100 items-center">
    <div class="card mx-auto w-full max-w-lg bordered shadow-xl">
      <div class="p-10">
        <h2 class="text-2xl font-semibold mb-2 text-center">
          Giris Yap
        </h2>
        <form @submit.prevent="loginBtn">
          <div class="mb-4">
            <div class="form-control w-full mt-4">
              <label for="email" class="label">
                Kullanıcı Adı
              </label>
              <input type="text" v-model="username" class="input input-bordered w-full">
            </div>
            <div class="form-control w-full mt-4">
              <label for="password" class="label">
                Şifre
              </label>
              <input type="password" v-model="password" class="input input-bordered w-full">
            </div>
          </div>
          <div class="text-right text-primary">
            <a href="/admin/forgot-password">
              <span class="text-sm inline-block hover:text-primary hover:underline hover:cursor-pointer transition duration-200">
                Sifremi Unuttum
              </span>
            </a>
          </div>
          <p class="text-center text-error mt-8">{{ errorText }}</p>
          <div class="form-control mt-6">
            <button id="login_form" class="btn btn-primary w-full" @click="loginBtn">
              Giris Yap
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
